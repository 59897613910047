/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import BabyCheckBox from './BabyCheckBox';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a href="" ref={ref} onClick={e => { e.preventDefault(); onClick(e); customClick(e); }} className="baby-list-card-list-toogle">
        {children}<img className="baby-list-card-list-vector" src="../img/vector.png" alt="list" />
    </a>
));

const customClick = (e) => {

    var elem = e.target;

    if (elem.nodeName === "IMG") {
        elem = elem.closest("A");
    }

    var link = elem;
    var text = link.firstChild;
    var vector = link.lastChild;
    var dropdown = link.closest(".dropdown");
    var card = dropdown.closest(".baby-list-card");

    if (dropdown.classList.contains("show")) {
        text.nodeValue = "Dérouler la liste";
        vector.classList.remove("revert");
        card.classList.remove("active");
    } else {
        text.nodeValue = "Enrouler la liste";
        vector.classList.add("revert");
        card.classList.add("active");
    }

}

const setActive = (e) => {

    var elem = e.target;

    if (elem.nodeName === "DIV" || elem.nodeName === "INPUT") {
        elem = elem.closest("BUTTON");
    }

    if (elem.classList.contains("active")) {
        elem.classList.remove("active");
    } else {
        elem.classList.add("active");
    }
    
}

function BabyCard(props) {

    return (
        <div className="baby-list-card">
            <div className="baby-list-card-pin-outer-dot">
                <div className="baby-list-card-pin-inner-dot"></div>
            </div>
            <div className="baby-list-card-title">{props.title}</div>
            <Image className="baby-list-card-img" src={props.img} alt={props.title}></Image>
            <hr className="baby-list-card-line"></hr>
            <Dropdown autoClose="outside"> 
                <Dropdown.Toggle as={CustomToggle}>
                    Dérouler la liste
                </Dropdown.Toggle>
                <Dropdown.Menu className="baby-list-card-list" flip={false}>
                        {props.listItems.map((item, i) => {
                            return <Dropdown.Item as="button" key={item} className="baby-list-card-list-item" name={props.title + " - " + item}  onClick={(e) => setActive(e)}>
                                        <BabyCheckBox text={item}></BabyCheckBox>
                                   </Dropdown.Item>;
                        })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
  );
}

export default BabyCard;