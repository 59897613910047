import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import BabyTopic from './shared/BabyTopic';
import BabyCard from './shared/BabyCard';
import Data from '../db/data.json';
import Cookies from 'js-cookie';

const sendData = () => {

    /*//json style 
    var data = {
        Name: document.getElementById("baby-list-final-form-name").value,
        Email: document.getElementById("baby-list-final-form-email").value,
        Address: document.getElementById("baby-list-final-form-address").value,
        Message: document.getElementById("baby-list-final-form-message").value,
        Choice: []
    }

    var elems = document.getElementsByClassName("baby-list-card-list-item active");

    Array.from(elems).forEach(elem =>
        data.Choice.push(elem.attributes.name.value)
    );
    var payload = JSON.stringify(data);
    */

    /*formData style*/
    var nameElem = document.getElementById("baby-list-final-form-name");
    var emailElem = document.getElementById("baby-list-final-form-email");
    var addressElem = document.getElementById("baby-list-final-form-address");
    var messageElem = document.getElementById("baby-list-final-form-message");


    var data = new FormData();

    data.append("Name", nameElem.value);
    nameElem.value = "";
    data.append("Email", emailElem.value);
    emailElem.value = "";
    data.append("Address", addressElem.value);
    addressElem.value = "";
    data.append("Message", messageElem.value);
    messageElem.value = "";

    var elems = document.getElementsByClassName("baby-list-card-list-item active");
    var choices = [];

    Array.from(elems).forEach(elem => {
        choices.push(elem.attributes.name.value);
        elem.classList.remove("active");
    });

    data.append("Choices", choices.toString());


    var scriptUrl = "https://script.google.com/macros/s/AKfycbwDwPzy2_CYo0yxdWjnILtOjWVmSxU75r5FUN3IZVEtUWun4ffBgjCkXoWI_9nDq7og/exec";

    fetch(scriptUrl, { method: 'POST', body: data })
    .then(res => {
        console.log(res);
    })
    .catch(err => console.log(err))
    
}

const checkIntroReadCookie = () => {
    var introRead = Cookies.get('IntroRead');
    return introRead ? introRead : false ;
}

function BabyList() {

    const [showFormModal, setShowFormModal] = useState(false);
    const [showExplain, setShowExplain] = useState(!checkIntroReadCookie());

    useEffect(() => {
        setExplain();
    });

    
    const setExplain = () => {

        var explain = document.getElementById("baby-list-intro-explain");
        var explainButton = document.getElementById("baby-list-intro-explain-button");
        
        if (showExplain) {
            explain.classList.remove("closed");
            explainButton.classList.add("closed");
            explainButton.classList.remove("opened");
        } else {
            explain.classList.add("closed");
            explainButton.classList.add("opened");
            explainButton.classList.remove("closed");
        }

        Cookies.set('IntroRead', true, { expires: 7 });
    }
    

  return (
      <Container fluid className="baby-list">

          {/* Title */}
          <Row className="justify-content-center mgb35">
            <div className="baby-list-title">
                  Roman
            </div>
          </Row>

          {/* Intro */}
          <Row id="baby-list-intro" className="justify-content-center mgb75">
             <div className="baby-list-intro">
                <div className="baby-list-intro-header mgb20">
                      HELLO, MOI C'EST ROMAN !<br/>
                      VOICI UN PETIT BOUT DE MA PERSONNE EN ATTENDANT DE VOUS RENCONTRER.
                </div>
                <div id="baby-list-intro-explain">
                    <div className="baby-list-intro-fw300 mgb20">
                        Je vous présente le concept de ce site :
                    </div>
                    <div className="baby-list-intro-fw500 mgb20">
                        Pour Participer, déroulez les cartes à thème et selectionnez les idées pour lesquelles vous aimeriez que mes parents utilisent vos dons.
                    </div>
                    <div className="baby-list-intro-fw500 mgb20">
                        Remplissez ensuite le formulaire avec vos coordonnées pour qu'ils soient informés de vos souhaits.
                        Les coordonnées de ma tirelire sont affichées juste avant le formulaire. Sans aucune obligation !
                    </div>
                    <div className="baby-list-intro-fw300 mgb20">
                        Si vous préférez choisir vous-même un petit cadeau,
                          je vous propose de vous inspirer de <a className="baby-list-intro-link" href='#monstyle' onClick={() => document.getElementById("monstyle").click()}>mon style</a>,<br />
                          pour que je reste toujours élégant.
                    </div>
                    <div className="baby-list-intro-fw500 mgb20">
                        Vous pouvez également contribuer à votre manière !
                        En me rendant un service, me prêter du matériel, faire un petit plat ou simplement en envoyant un petit message plein d'amour.
                    </div> 
                </div>
                <Image id="baby-list-intro-explain-button" rounded src="../img/VectorButton.png" onClick={() => { setShowExplain(!showExplain); setExplain(); }}></Image>
             </div>
          </Row>

          {/* Topics */ }
          <Row className="justify-content-center mgb75">
              {Data.Topics.map((topic, i) => {
                  return <BabyTopic id={topic.id} key={topic.title} centerDotColor={topic.centerDotColor} title={topic.title} action={topic.action}></BabyTopic>;
              })}
          </Row>

          {/* Cars */}
          <Row id="baby-list-cards" className="justify-content-center mgb75" >
              {Data.Cards.map((card, i) => {
                  return <BabyCard key={card.title} title={card.title} img={`../img/${card.img}`} listItems={card.items}></BabyCard>;
              })}
          </Row>

          {/* Style */}
          <Row id="baby-list-style" className="justify-content-center mgb75" hidden>
              <div className="baby-list-style-block mgb35">
                  <div className="baby-list-style-title mgb20">Mon futur dressing</div>
                  <div className="baby-list-style-text mgb35">
                      <a className="baby-list-style-text-fw600" href="https://www.citrongrenadine.be" target="_blank"> Citron Grenadine</a>,
                      <a className="baby-list-style-text-fw600" href="https://www.sourisverte.be" target="_blank"> la souris verte</a>,
                      <a className="baby-list-style-text-fw600" href="https://www.halinageorges.be" target="_blank"> Halina Georges </a>...<br />
                      J'aime faire vivre les petites boutiques belge conceptuelles.<br />
                      Je choisi des matières naturelles, du coton, du lin, des mailles, du double gaze de coton.<br />
                      Mes couleurs sont douces, beaucoup de blanc, d’avoine, du terracotta, du sable et une touche de sauge.<br />
                  </div>
                  <Image fluid className="baby-list-style-img" src="../img/Fringues.png"></Image>
              </div>
              <div className="baby-list-style-block mgb35">
                  <div className="baby-list-style-title mgb20">Mon nid actuel </div>
                  <Image fluid className="baby-list-style-img" src="../img/Chambre.png"></Image>
              </div>
              <div className="baby-list-style-block mgb35">
                  <div className="baby-list-style-title mgb20">Ma future chambre</div>
                  <div className="baby-list-style-text mgb35">
                      Des matières naturelles comme le bois et confortable comme le lin.<br />
                      Des jouets éthiques, des petites peluches aux couleurs douces,<br />
                      des détails mignons en paille et rotin...
                  </div>
                  <Image fluid className="baby-list-style-img" src="../img/FuturChambre.png"></Image>
              </div>
          </Row>

          {/* Family */}
          <Row id="baby-list-family" className="justify-content-center mgb75" hidden>
              <div className="baby-list-family-block mgb35">
                  <div className="baby-list-family-title mgb20">Moi !</div>
                  <Image className="baby-list-family-img mgb20" src="../img/Roman.png" alt="Roman"></Image>
                  <div className="baby-list-family-text">
                      Roman, June, Angie, Paul<br />
                      De Kan Katz
                  </div>
              </div>
              <div className="baby-list-family-block mgb35">
                  <div className="baby-list-family-title mgb20">Mes Parents</div>
                  <Image className="baby-list-family-img mgb20" src="../img/Parents.png" alt="Parents"></Image>
                  <div className="baby-list-family-text">Amandine Katz & Manuel De Kan</div>
              </div>
              <div className="baby-list-family-block mgb35">
                  <div className="baby-list-family-title mgb20">Mes Grands Mères</div>
                  <div className="baby-list-family-text">Angelina De Paola & Marie-Angèle Henno</div>
              </div>
              <div className="baby-list-family-block mgb35">
                  <div className="baby-list-family-title mgb20">Ma Marraine</div>
                  <div className="baby-list-family-text">Delphine Mahieu</div>
              </div>
          </Row>

          {/*Final Form*/ }
          <Row id="baby-list-final" className="baby-list-final">
              <div className="baby-list-final-title">
                  Finaliser la participation
              </div>
              <div className="baby-list-final-text">
                  Soyez libre de faire un cadeau à votre manière !<br/>
                  En choisissant un service ou en participant financièrement à mes futures achats, <br />
                  mais surtout en me laissant un petit message par ici.
              </div>
              <div className="baby-list-final-account mgb75">
                  BE48 6511 5755 9227
              </div>
              <div className="baby-list-final-form">
                  <input id="baby-list-final-form-name" placeholder="Ton nom" />
                  <input id="baby-list-final-form-email" type="email" placeholder="Ton email" />
                  {/*
                      <div id="baby-list-final-form-baby-check" onClick={(e) => setActive(e)}>
                          <BabyCheckBox text='Recevoir un faire-part'></BabyCheckBox>
                      </div>
                  */}
                  <input id="baby-list-final-form-address" placeholder="Ton adresse postale" />
                  <input id="baby-list-final-form-message" placeholder="Ton message" />
                  <button onClick={() => { setShowFormModal(true); sendData(); }}>Envoyer</button>
              </div>
          </Row>

          {/*Modal Form*/}
          <Modal centered size="lg" className="baby-list-final-form-modal" show={showFormModal} onHide={() => setShowFormModal(false)}>
              <Modal.Body className="baby-list-final-form-modal-body">
                  <Image rounded className="baby-list-final-form-modal-img" src="../img/ModalBibi.png" ></Image>
                  <div className="baby-list-final-form-modal-title mgb35">
                      MERCI DE PARTICIPER A MES PREMIERS PAS
                      DANS CETTE NOUVELLE AVENTURE.
                  </div>
                  <div className="baby-list-final-form-modal-text mgb20">
                      Le compte de mes parents, qui feront bon usage de vos belles attentions:
                  </div>
                  <div className="baby-list-final-form-modal-account mgb20">
                      BE48 6511 5755 9227
                  </div>
                  <div className="baby-list-final-form-modal-text  mgb75">
                      À très vite,<br/>
                      Amandine, Manu et Roman.
                  </div>
              </Modal.Body>
          </Modal>

      </Container>

  );
}

export default BabyList;
