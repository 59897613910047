import React from 'react';

const changeDisplay = (e, action ) => {

    action.show.forEach(id => {
        try {
            document.getElementById(id).removeAttribute("hidden")
        } catch {
            console.log(id);
        }
    });

    action.hide.forEach(id => {
        try {
            document.getElementById(id).setAttribute("hidden", "")
        } catch {
            console.log(id);
        }
    });

}

function BabyTopic(props) {
    return (
        <div id={props.id} className="baby-list-topic">
            <div className="baby-list-topic-outer-dot">
                <a href={"#" + props.id} className="baby-list-topic-inner-dot" style={{ backgroundColor: props.centerDotColor }} onClick={(e) =>  changeDisplay(e, props.action)}></a>
            </div>
            <div className="baby-list-topic-title">{props.title}</div>
        </div>
  );
}

export default BabyTopic;