import './App.css';
import BabyList from './Components/BabyList';

function App() {
  return (
      <BabyList></BabyList>
  );
}

export default App;
