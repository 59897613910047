import React from 'react';

function BabyCheckBox(props) {
    return (
        <>
            <div className="baby-list-baby-checkbox-outer-dot">
                <div className="baby-list-baby-checkbox-inner-dot"></div>
            </div>
            <div className="baby-list-baby-checkbox-text">{props.text}</div>
        </>
  );
}

export default BabyCheckBox;